<template>
  <el-button type="text" @click="handleOpen">Changer mot de passe</el-button>

  <el-dialog
    v-model="dialogVisible"
    title="Réinitialiser le mot de passe"
    width="30%"
    :before-close="handleClose"
  >
    <el-form ref="formEmail" :model="form">
      <el-form-item
        prop="email"
        label="Email"
        :rules="[
          {
            required: true,
            message: 'Veuillez saisir l\'adresse e-mail',
            trigger: 'blur',
          },
          {
            type: 'email',
            message: 'Veuillez saisir l\'adresse e-mail correcte',
            trigger: ['blur', 'change'],
          },
        ]"
      >
        <el-input
          v-model="form.email"
          class="w-100"
          size="large"
          placeholder="Taper votre email"
          :prefix-icon="MessageIcon"
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="handleSend('formEmail')"
          >Envoyer</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  data() {
    return {
      form: {
        email: null,
      },
      dialogVisible: false,
    }
  },
  methods: {
    handleOpen() {
      this.form.email = null
      this.dialogVisible = true
    },
    handleClose() {
      this.dialogVisible = false
    },
    handleSend(formEmail) {
      this.$refs[formEmail].validate((valid) => {
        if (valid) {
          this.$store
            .dispatch('auth/resetPassword', {
              data: {
                email: this.form.email,
              },
            })
            .then(() => {
              ElMessage({
                message: 'Email envoyé',
                type: 'success',
              })
              setTimeout(() => {
                this.monitor.visible = false
              }, 2000)
            })
        }
      })
    },
  },
}
</script>

<style></style>
