<template>
  <div class="template">
    <el-row :gutter="20" style="margin-top: 5%">
      <el-col :span="12" :offset="6">
        <h1 style="margin-left: 250px">Informations personnel</h1>
        <el-form ref="formUser" :model="form" :rules="rules">
          <el-form-item
            class="champs"
            label="Email "
            :label-width="formLabelWidth"
            prop="email"
          >
            <el-input
              v-model="form.email"
              autocomplete="off"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item
            class="prenom"
            label="Prénom "
            :label-width="formLabelWidth"
            prop="firstname"
          >
            <el-input v-model="form.firstname" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item
            class="nom"
            label="Nom "
            :label-width="formLabelWidth"
            prop="lastname"
          >
            <el-input v-model="form.lastname" autocomplete="off"></el-input>
          </el-form-item>

          <div class="enregistrer">
            <el-button type="primary" @click="onSubmit('formUser')"
              >Enregistrer</el-button
            >
          </div>
          <div class="mdp">
            <span>Mot de passe : <ResetPassword /> </span
          ></div>

          <el-row v-if="form.retailer" justify="start" align="middle">
            <el-col :span="6" style="align-itel">
              <span>Revendeur : </span>
              <span>{{ form.retailer }}</span>
            </el-col>
            <el-col :span="2">
              <el-popconfirm
                confirmButtonText="Oui"
                cancelButtonText="Annuler"
                @confirm="handleLeave()"
                :icon="InfoIcon"
                iconColor="#F56C6C"
                title="Voulez vous vraiment quitter ce revendeur ?"
              >
                <template #reference>
                  <el-button size="small" type="danger" plain
                    >Supprimer</el-button
                  >
                </template>
              </el-popconfirm>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ResetPassword from '@/components/ResetPassword.vue'
import { ElMessage } from 'element-plus'
import { mapGetters } from 'vuex'
export default {
  components: { ResetPassword },
  data() {
    return {
      dialogFormAdd: false,
      formLabelWidth: '180px',
      emailOptions: [],
      loading: false,
      form: {
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        retailer: null,
      },
      confirmPassword: null,
      rules: {
        firstname: [
          {
            required: true,
            message: 'Veuillez saisir le prénom',
            trigger: 'change',
          },
        ],
        lastname: [
          {
            required: true,
            message: 'Veuillez saisir le nom',
            trigger: 'change',
          },
        ],
        email: [
          {
            required: true,
            message: "Veuillez saisir l'email",
            trigger: 'change',
          },
        ],
        password: [
          {
            required: false,
            message: 'Veuillez saisir le mot de passe',
            trigger: 'change',
          },
        ],
      },
    }
  },
  mounted() {
    this.populate(this.user)
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      token: 'auth/token',
      member: 'auth/member',
      users: 'users/users',
      organisation: 'organisations/organisation',
    }),
  },
  methods: {
    handleLeave() {
      let payload = {
        id: this.user.id,
        data: {
          retailerId: null,
          appRole: null,
        },
      }
      this.$store.dispatch('users/updateUser', payload).then(() => {
        this.$store.dispatch('auth/logout', {}).then(() => {
          this.$router.push('/login')
        })
      })
    },
    populate(user) {
      this.form.firstname = user.firstname
      this.form.lastname = user.lastname
      this.form.email = user.email
      this.form.retailer = user.retailer?.name ?? null
    },
    onSubmit(formUser) {
      this.$refs[formUser].validate((valid) => {
        if (valid) {
          let data = {
            firstname: this.form.firstname,
            lastname: this.form.lastname,
          }
          if (this.form.password) {
            data.password = this.form.password
          }
          let payload = {
            id: this.user.id,
            data: {
              ...data,
            },
          }
          this.$store
            .dispatch('users/updateUser', payload)
            .then((data) => {
              this.$store.commit('auth/SET_USER', data)
              ElMessage({
                message: 'Utilisateur mis à jour avec succès',
                type: 'success',
              })
            })
            .catch((error) => console.error(error))
        } else return false
      })
    },
  },
}
</script>

<style>
.enregistrer {
  margin-left: 490px;
}

.champs {
  margin-left: 17px;
}

.prenom {
  margin-left: 16px;
  width: 97.2%;
}
.nom {
  margin-left: 15.7px;
}
.mdp {
  margin-left: 197px;
  margin-top: -30px;
}
</style>
